import React from "react"
import Contact from "../../components/Contact/Contact"
import Layout from "../../layouts/Layout"

const ContactPage = () => {
  const metadata = {
    title: "Get in touch",
    description:
      "Get to know my professional experience in Web designer with my stories in the tech industry.",
    cover: "/images/covers/contact_cover.png",
    type: "website",
    path: "/contact",
  }

  return (
    <Layout metadata={metadata}>
      <Contact />
    </Layout>
  )
}
export default ContactPage
